<template>
    <div>
        <div class="card">
            <h3 class="card_title">Nachkalkulation</h3>
            <div id="infoGrid" class="padding">
                <p>Reale Fertigungszeit:</p>
                <input type="number" :value="values.real_manufacturingtime" @input="changeValue('real_manufacturingtime', $event.target.value)">
                <p>s/Stück</p>
                
                <p>Realer Materialbedarf:</p>
                <input type="number" :value="values.real_material" @input="changeValue('real_material', $event.target.value)">
                <p></p>
                
                <p>Reale Rüstzeit:</p>
                <input type="number" :value="values.real_setuptime" @input="changeValue('real_setuptime', $event.target.value)">
                <p>min</p>

                <p>Reale Werkzeugkosten:</p>
                <input type="number" :value="values.real_toolcost" @input="changeValue('real_toolcost', $event.target.value)">
                <p>€</p>
            </div>
        </div>

        <div class="card">
            <h3 class="card_title">Nacharbeit und Ausschuss</h3>
            <div class="padding">
                <div class="box">
                    <p><b>Erforderliche Nacharbeit:</b></p>
                    <div class="flex" style="margin-bottom: .75em">
                        <p class="fitContent radius dark pointer padding_small" :class="{blue: values.rework}" @click="changeValue('rework', true)">Ja</p>
                        <p class="fitContent radius dark pointer padding_small" :class="{blue: !values.rework}" @click="changeValue('rework', false)">Nein</p>
                        <searchlist v-if="values.rework" style="margin-left: 1em" :values="rework_processes" :placeholder="values.rework_processes" :firstsel="0" @select="changeValue('rework_processes', $event)"></searchlist>
                    </div>
                    <div v-if="values.rework" class="indent">
                        <div class="flex">
                            <p class="dark padding_small radius" v-for="i in values.rework_processes" :key="i" @click="changeValue('rework_processes', i)">{{dict(i)}}</p>
                        </div>
                    </div>
                </div>

                <div class="box">
                    <p><b>Produzierter Ausschuss:</b></p>
                    <div id="infoGrid">
                        <p>Einstellteile:</p>
                        <input type="number" :value="values.manufactured_adjustmentparts" @input="changeValue('manufactured_adjustmentparts', $event.target.value)" min="0">
                        <p>Stück</p>
                        
                        <p>Ausschussteile:</p>
                        <input type="number" :value="values.manufactured_rejected" @input="changeValue('manufactured_rejected', $event.target.value)" min="0">
                        <p>Stück</p>
                        
                        <p>Gute Teile:</p>
                        <input type="number" :value="values.manufactured_good" @input="changeValue('manufactured_good', $event.target.value)" min="0">
                        <p>Stück</p>

                    </div>
                </div>
                

            </div>
        </div>

    </div>
</template>

<script>
import Vue from "vue";
export default {
    name: "Recalculation",
    data() {
        return {
            rework_processes: [
                "deburring",

            ]
        }
    },
    computed: {
        values() {
            if(!this.$store.state.calculation.recalculation) Vue.set(this.$store.state.calculation, "recalculation", {})
            return this.$store.state.calculation.recalculation
        }
    },
    methods: {
        dict(c) {
            return this.$store.state.dict[c] || c
        },
        changeValue(key, value) {
            let recalculation = this.$store.state.calculation.recalculation
            if (key == "rework_processes") {
                if (!recalculation.rework_processes) Vue.set(recalculation, "rework_processes", [])
                console.log(recalculation.rework_processes);
                if (recalculation.rework_processes.indexOf(value) == -1) {
                    recalculation.rework_processes.push(value)
                }
                else recalculation.rework_processes.splice(recalculation.rework_processes.indexOf(value), 1)
            }
            else Vue.set(recalculation, key, value)
        }
    }
}
</script>

<style scoped>
    #infoGrid{
        display: grid;
        grid-template-columns: auto 4em 4em;
        grid-column-gap: .5em;
        grid-row-gap: 4px;
    }
</style>